import React from "react";
import "../about/about.css";

const About = () => {
  return (
    <>
      <div className="about" id="about">
        <div className="container">
          <div className="row">
            <div className="col-lg-6 offset-lg-3">
              <h1 className="h1">
                <span style={{ color: "white"}}>"</span>
                TOGETHER
                <span style={{ color: "white" }}> WE CAN GO </span>
                HIGHER
                <span style={{ color: "white"}}>"</span>
              </h1>
              <p>
                Acara Appreciation Night adalah sebuah perhelatan tahunan yang
                diadakan NIEC INDONESIA untuk mengapresiasi pihak yang terlibat
                dalam terwujudnya mimpi para student kami untuk bisa kuliah di
                luar negeri. Acara ini dihadiri oleh Bupati Badung, Dinas Kota
                Denpasar, Partner sekolah di seluruh Indonesia, Institusi
                pendidikan luar negeri, para orang tua student yang dimana
                anaknya sedang menempuh pendidikan di luar negeri, Selain itu,
                acara ini juga dihadiri oleh tokoh-tokoh yang memiliki pengaruh
                besar terhadap lingkungan sosial.
              </p>

              <ul className="list">
                <li>Gala  Dinner & Networking </li>
                <li>Opening Ceremonial</li>
                <li>Super Student Award 2024</li>
                <li>⁠Student & Parents Inspiring Stories </li>
                <li>Motivational Speech</li>
                <li>Super Student Apps Latest Updates </li>
                <li>⁠Inspiring & Rising Starts Partners Institutions</li>
                <li>Penyerahan piala ke partner sekolah</li>
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default About;
