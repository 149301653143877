import React, { useEffect, useState } from "react";
import "../header/header.css";
import BannerPirate from "../../assets/img/APRE/BannerAppNight_Wide[1].jpg";
import BannerFestival from "../../assets/img/APRE/logo-niec.bcfd59b8b3c7664dc1f6.png";

const Navbar = () => {
  return (
    <div className="header" id="header">
      <div className="atas">
        <img className="banner-festival" src={BannerFestival} />
        <img className="banner-pirate" src={BannerPirate} alt="banner" />

      </div>
    </div>
  );
};

export default Navbar;
