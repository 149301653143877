import { Routes, Route } from "react-router-dom";
import Index from "./pages/home";
import Ticket from "./pages/ticket";
import Attendance from "./pages/attendance";
import Ambassador from "./pages/ambassador";

export default function App() {
  return (
    <Routes>
      <Route path="/" element={<Index />} />
      <Route path="/ticket/:id" element={<Ticket />} />
      {/* <Route path="/attendance" element={<Attendance />} />
      <Route path="/ambassador" element={<Ambassador />} /> */}
    </Routes>
  );
}