import React from "react";
import "../footer/footer.css";

const Footer = () => {
  return (
    <footer>
      <p>2024 © NIEC Indonesia</p>
    </footer>
  );
};

export default Footer;
