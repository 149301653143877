import React, { useEffect, useState } from "react";
import { useForm, Controller } from "react-hook-form";
import Select from "react-select";
import "../form/form-register.css";
import "react-phone-input-2/lib/bootstrap.css";
import API_URL from "../../Api/index";
import Swal from "sweetalert2";
import banner from "../../assets/img/APRE/barcode_ss.6916c490c276565cb4c1.png";
import { Row } from "reactstrap";
import axios from "axios";
import PhoneInput from "react-phone-input-2";

const FormRegister = () => {
  const {
    register,
    handleSubmit,
    formState: { errors },
    control,
  } = useForm();
  const [kabkota, setKabkota] = useState([]);
  const [btnLoading, setBtnLoading] = useState(false);
  const [selectedCategory, setSelectedCategory] = useState("");
  const [selectedSchool, setSelectedSchool] = useState(null);

  useEffect(() => {
    const fetchKabkota = async () => {
      try {
        const response = await fetch(
          `${API_URL.superStudent}/api/v1/application/sekolah-indonesia/7`
        );
        if (response.ok) {
          const responseData = await response.json();
          if (responseData.status === "Success") {
            setKabkota(responseData.data);
          } else {
            throw new Error(responseData.message || "Gagal mengambil data");
          }
        } else {
          throw new Error("Response not OK");
        }
      } catch (error) {
        console.error("Error:", error);
        Swal.fire({
          icon: "error",
          text:
            error.message ||
            "Terjadi kesalahan saat mengambil data instansi. Silakan coba lagi nanti.",
        });
      }
    };

    fetchKabkota();
  }, []);

  const onSubmit = async (data) => {
    try {
      setBtnLoading(true);
      const formattedPhone = data.phone.replace(/^\+/g, '');

      // const formattedKeterangan = `${selectedCategory} - ${data.keterangan}`;
      // const formattedKeterangan = `${selectedCategory} - ${data.keterangan}, kehadiran( ${data.attendance})`;
      const formattedKeterangan = JSON.stringify({
        category: selectedCategory,
        description: data.keterangan,
        attendance: data.attendance,
      });
      const postData = {
        ...data,
        province_id: selectedSchool ? selectedSchool.province_id : null,
        id_kabkota: selectedSchool ? selectedSchool.id_kabkota : null,
        // id_konten: 52,
        id_konten: 162,
        keterangan: formattedKeterangan,

        attandance: data.attandance,
        phone: formattedPhone,
      };

      console.log(postData);

      const response = await axios.post(
        `${API_URL.superStudent}/event/register`,
        postData,
        {
          headers: {
            Authorization:
              "Bearer eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ",
            "Content-Type": "application/json",
          },
        }
      );

      if (response.status === 200) {
        Swal.fire({
          icon: "success",
          text: "Registrasi berhasil!",
        });
      } else {
        throw new Error("Registrasi gagal");
      }
    } catch (error) {
      console.error("Error:", error);
      Swal.fire({
        icon: "error",
        text:
          error.message ||
          "Terjadi kesalahan saat melakukan registrasi. Silakan coba lagi nanti.",
      });
    } finally {
      setBtnLoading(false);
    }
  };

  const handleSchoolChange = (selectedOption) => {
    setSelectedSchool(selectedOption);
  };

  const options = kabkota.map((sekolah) => ({
    value: sekolah.kode_sekolah,
    label: sekolah.nama_sekolah,
  }));

  let additionalForm = null;

  switch (selectedCategory) {
    case "Instansi":
      additionalForm = (
        <div className="form-input">
          <label htmlFor="sekolah" className="form-label">
            Select School
          </label>
          <Controller
            name="sekolah"
            control={control}
            rules={{ required: "School must be selected" }}
            render={({ field }) => (
              <Select
                {...field}
                value={selectedSchool}
                onChange={(value) => {
                  handleSchoolChange(value);
                  field.onChange(value);
                }}
                options={options}
                className={` ${errors.sekolah?.message && "is-invalid"}`}
                placeholder="Asal Sekolah"
                isSearchable
              />
            )}
          />
          {errors.sekolah?.message && (
            <div className="invalid-feedback">{errors.sekolah?.message}</div>
          )}
        </div>
      );
      break;
    case "Orang_Tua":
      additionalForm = (
        <div className="form-input">
          <label htmlFor="keterangan" className="form-label">
            Student's Name
          </label>
          <input
            {...register("keterangan", {
              required: "Student's Name is required",
            })}
            type="text"
            className={`form-control ${
              errors.keterangan?.message && "is-invalid"
            }`}
            id="keterangan"
            placeholder="Student's Name"
          />
          {errors.keterangan?.message && (
            <div className="invalid-feedback">{errors.keterangan?.message}</div>
          )}
        </div>
      );
      break;
    case "School_Patner":
      additionalForm = (
        <div className="form-input">
          <label htmlFor="keterangan" className="form-label">
            School Partner
          </label>
          <input
            {...register("keterangan", {
              required: "University Name Wajib Diisi",
            })}
            type="text"
            className={`form-control ${
              errors.keterangan?.message && "is-invalid"
            }`}
            id="keterangan"
            placeholder="University Name"
          />
          {errors.keterangan?.message && (
            <div className="invalid-feedback">{errors.keterangan?.message}</div>
          )}
        </div>
      );
      break;
    case "Dinas":
      additionalForm = (
        <div className="form-input">
          <label htmlFor="keterangan" className="form-label">
            Asal Dinas
          </label>
          <input
            {...register("keterangan", { required: "Nama Dinas Wajib Diisi" })}
            type="text"
            className={`form-control ${
              errors.keterangan?.message && "is-invalid"
            }`}
            id="keterangan"
            placeholder="Nama Dinas"
          />
          {errors.keterangan?.message && (
            <div className="invalid-feedback">{errors.keterangan?.message}</div>
          )}
        </div>
      );
      break;
    default:
      additionalForm = null;
      break;
  }

  return (
    <>
      <div
        className="justify-content-center"
        style={{ marginTop: "10px", marginBottom: "-50px" }}
      >
        <div lg="11" className="d-flex justify-content-center">
          <img className="img-fluid" src={banner} alt="Banner Daftar" />
        </div>
      </div>
      <div className="form-register">
        <h1 color="black">Form Registrasi</h1>
        <form onSubmit={handleSubmit(onSubmit)}>
          <div className="form-input">
            <label htmlFor="kategori" className="form-label">
              Category
            </label>
            <select
              {...register("kategori", {
                required: "Category must be selected",
              })}
              className={`form-control ${
                errors.kategori?.message && "is-invalid"
              }`}
              id="kategori"
              onChange={(e) => setSelectedCategory(e.target.value)}
            >
              <option value="" defaultValue>
                Select Category
              </option>
              <option value="Student Granted">Student Granted</option>
              <option value="Student Progress">Student Progress</option>
              <option value="Instansi">Instansi</option>
              <option value="Orang_Tua">Orang Tua</option>
              <option value="Dinas">Dinas</option>
              <option value="Ambassador">Ambassador</option>
              <option value="IHMS">IHMS</option>
              <option value="School_Patner">School Patner</option>
            </select>
            {errors.kategori?.message && (
              <div className="invalid-feedback">{errors.kategori?.message}</div>
            )}
          </div>
          {additionalForm}{" "}
          {/* Menampilkan form tambahan sesuai dengan kategori yang dipilih */}
          <div className="form-input">
            <label htmlFor="name" className="form-label">
              Name
            </label>
            <input
              {...register("name", { required: "Full Name is required" })}
              type="text"
              className={`form-control ${errors.name?.message && "is-invalid"}`}
              id="name"
              placeholder="Full Name"
            />
            {errors.name?.message && (
              <div className="invalid-feedback">{errors.name?.message}</div>
            )}
          </div>
          <div className="form-input">
            <label htmlFor="email" className="form-label">
              Email
            </label>
            <input
              {...register("email", {
                required: "Email is required",
                pattern: {
                  value: /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i,
                  message: "Email tidak valid",
                },
              })}
              type="text"
              className={`form-control ${
                errors.email?.message && "is-invalid"
              }`}
              id="email"
              placeholder="name@example.com"
            />
            {errors.email?.message && (
              <div className="invalid-feedback">{errors.email?.message}</div>
            )}
          </div>
          <div className="form-input">
            <label htmlFor="attendance" className="form-label">
              Number of Attendees
            </label>
            <input
              {...register("attendance", {
                required: "Number of Attendees must be filled with a number",
                pattern: {
                  value: /^\d+$/,
                  message:
                    "Please enter only numbers for the number of attendees",
                },
              })}
              type="number"
              className={`form-control ${
                errors.attendance?.message && "is-invalid"
              }`}
              id="attendance"
              placeholder="Number of Attendees"
            />
            {errors.attendance?.message && (
              <div className="invalid-feedback">
                {errors.attendance?.message}
              </div>
            )}
          </div>
          <div className="form-input">
            <label htmlFor="phone" className="form-label">
              No Whatsapp
            </label>
            <Controller
              control={control}
              name="phone"
              rules={{ required: "Nomor Whatsapp Wajib Diisi" }}
              render={({ field: { ref, ...field } }) => (
                <PhoneInput
                  {...field}
                  inputExtraProps={{
                    ref,
                    required: true,
                  }}
                  country={"id"}
                  countryCodeEditable={false}
                  inputClass={`${errors.phone?.message && "input-invalids"}`}
                  inputStyle={{ width: "100%", height: "45px" }}
                />
              )}
            />
            {errors.phone?.message && (
              <div className="text-invalids">{errors.phone?.message}</div>
            )}
          </div>
          <div className="form-input">
            <button
              disabled={btnLoading}
              type="submit"
              className="btn btn-register"
            >
              {btnLoading ? "Registrasi..." : "Registrasi"}
            </button>
          </div>
        </form>
      </div>
      <Row
        className="d-flex justify-content-center"
        style={{ marginTop: "80px", marginBottom: "50px" }}
      ></Row>
    </>
  );
};

export default FormRegister;
