import axios from 'axios'

// const url = 'http://103.41.205.87:80/api/v1/application';
// const superStudent = 'http://103.41.205.87:80';
// const urlEvent = 'http://103.41.205.87:80/event';
// const id_event = 39;

const url = 'https://superstudent.niecindonesia.com/api/v1/application';
const superStudent = 'https://superstudent.niecindonesia.com';
const urlEvent = 'https://superstudent.niecindonesia.com/event';
const id_event = 122;

const baseUrl =  axios.create({
    baseURL: url,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json'
    }
})

const eventUrl =  axios.create({
    baseURL: urlEvent,
    timeout: 10000,
    headers:{
        'Content-Type': 'application/json',
        'Authorization': `Bearer eyJzdWIiOiIxMjM0NTY3ODkwIiwibmFtZSI6IkpvaG4gRG9lIiwiaWF0IjoxNTE2MjM5MDIyfQ`
    }
})

const API_URL = {
    superStudent,
    baseUrl,
    eventUrl,
    id_event
}

export default API_URL