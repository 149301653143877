import "../../App.css";
import Header from "../../components/header/Header";
import FormRegister from "../../components/form/FormRegister";
import About from "../../components/about/About";
import Location from "../../components/location/Location";
import Partner from "../../components/partner/partner";
import Footer from "../../components/footer/Footer";

function App() {
  return (
    <>
      <Header />
      <About />
      <Location />
      <FormRegister />
      {/* <Partner /> */}
      <Footer />
    </>
  );
}

export default App;
