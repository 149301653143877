import React, { useEffect, useRef, useState } from 'react'
import '../location/location.css'
import ticket from '../../assets/img/APRE/TanganSuperStudent.png'
import background from '../../assets/img/APRE/BannerAppNight_Wide2.jpg'


const Location = () => {

    return (

        <section className="location" id='lokasi'>
            <img className='ticket' src={ticket} alt="" />
        </section>
    )
}

export default Location